import React, { useState } from "react"

import CookieBar from "../cookieBar"
import OfferBanner from "../offerBanner"

import logo from "../../styles/assets/vegly-logo.svg"
import styles from "./layout.module.css"

function SiteLogo() {
    return (
        <div className={styles.siteLogo}>
            <a href="/">
                <img src={logo} alt="Vegly logo"/>
            </a>
        </div>
    )
}

export default function Layout({ mainClass = "main", children }) {
    const [toggleMobileNav, setToggleMobileNav] = useState(false);

    return (
        <div id={styles.siteWrapper}>
            <OfferBanner></OfferBanner>
            <header id={styles.siteHeader} className="global-site-header">
                <div className={styles.smOnly}>
                    <div className={styles.hamburger} onClick={() => setToggleMobileNav(!toggleMobileNav)}>
                        <span></span><span></span><span></span>
                    </div>
                    <SiteLogo/>
                </div>
                <div className={[styles.navContainer, toggleMobileNav ? styles.open : ''].join(' ')}>
                    <nav className={styles.siteNav}>
                        <a href="/">Home</a>
                        <a href="/faqs">FAQ</a>
                    </nav>
                    <SiteLogo/>
                    <nav className={styles.secondaryNav}>
                        <a href={`${process.env.GATSBY_APP_URL}/signin`}>Sign in</a>
                        <a href={`${process.env.GATSBY_APP_URL}/signup`} className={["btn", styles.navSignup].join(' ')}>Sign up</a>
                    </nav>
                </div>
            </header>
            <main className={mainClass}>
                { children }
            </main>
            <footer id={styles.siteFooter}>
                <div className="logo"></div>
                <nav>
                    <a>© 2020 Vegly</a>
                    {/** 
                    <a href="/subscription">Subscription</a>
                    */}
                    <a href="/recipes">Recipes</a>
                    <a href="/faqs">FAQ</a>
                    <a href="/terms">Terms of Service</a>
                    <a href="/privacy">Privacy Policy</a>
                </nav>
                {/** 
                <nav className="social">
                    <a className="social-instagram" href="https://www.instagram.com/eatvegly/" target="_blank"></a>
                    <a className="social-facebook" href="https://www.facebook.com/eatvegly/" target="_blank"></a>
                    <a className="social-email" href="mailto:hello@eatvegly.com"></a>
                </nav>
                */}
                <p className={styles.disclaimer}>If you have specific dietary requirements or medical conditions always seek expert advice. <br/>You must not rely on the information on this website as an alternative to medical advice from your doctor or other professional healthcare provider.</p>
            </footer>
            <CookieBar/>
        </div>
    )
}