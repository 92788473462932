import React from "react"
import styles from "./styles.module.css"

export default function() {
    return(
        <div className={styles.banner}>
            <div className={styles.innerWrapper}>
                <span>
                    Autumn Special Offer&nbsp;-&nbsp;<span className={styles.txtbold}>&nbsp;35% off</span> all Vegly passes. Sign up for free to view available passes
                </span>
                <span>
                    &nbsp;
                </span>
            </div>
        </div>
    )
}